// Feature Names for the feature flags
export const Features = {
  ACCOUNT_SELLER_KEYS_SYNC_FLOW_UPGRADE: 'fflag.account.mwsKeysSyncFlowUpgrade',
  AI_CHATBOT: 'fflag.ai.chatbot',
  ADS_ANALYTICS_UPGRADE_PERFORMANCE_TAB:
    'fflag.adsAnalytics.upgradePerformanceTab',
  COMPETITIVE_INTELLIGENCE_HORUS:
    'fflag.competitiveIntelligence.newCompetitorAlgorithm',
  COMPETITIVE_INTELLIGENCE_UPGRADE_INCENTIVE:
    'fflag.competitiveIntelligence.upgradeIncentive',
  COMPETITIVE_INTELLIGENCE_VALIDATION:
    'fflag.competitiveIntelligence.validation',
  COMPETITIVE_INTELLIGENCE_LANDING_PAGE_V2:
    'fflag.competitiveIntelligence.landingPageV2',
  KEYWORDS_SHARE_OF_VOICE: 'fflag.keywords.shareOfVoice',
  KEYWORD_SCOUT_BRAND_ANALYTICS_COLUMNS:
    'fflag.keywords.ksBrandAnalyticsColumns',
  KEYWORD_SCOUT_DISTRIBUTION: 'fflag.keywords.keywordsDistribution',
  KEYWORD_SCOUT_BADGE_COLUMN: 'fflag.keywords.ksBadgeColumn',
  KEYWORD_SCOUT_AD_CAMPAIGNS_COLUMN: 'fflag.keywords.kwsAdCampaignsColumn',
  MONEY_BACK_GUARANTEE: 'fflag.account.moneyBackGuarantee',
  OPPORTUNITY_FINDER_TABLE_SPARKLINE: 'fflag.opportunityFinder.sparkline',
  OPPORTUNITY_FINDER_V2: 'fflag.opportunityFinder.openSearch',
  NEGATIVE_KEYWORDS_MODAL_V2: 'fflag.keywords.negativeKeywordsModalV2',
  PPC_UPDATE_BANNERS: 'fflag.account.ppcUpdatePermissionsBanner',
  PROFIT_OVERVIEW_DEMO_PREVIEW: 'fflag.profitOverview.demoPreview',
  SELLER_FEATURES_DISABLED: 'fflag.sellerFeatures.disabled',
  SELLER_FEATURES_MAINTENANCE: 'fflag.sellerFeatures.maintenance',
  LISTING_BUILDER_DISABLE_SYNC: 'fflag.listingBuilder.disableSync'
}
