import { t } from 'services/i18n'

export const REGIONS_LABELS = {
  na: t('generic:Regions.na', 'North America'),
  eu: t('generic:Regions.eu', 'Europe'),
  fe: t('generic:Regions.fe', 'Far East')
}

/**
  Based on https://developer-docs.amazon.com/sp-api/docs/marketplace-ids
  and https://developer-docs.amazon.com/sp-api/docs/seller-central-urls

  Please maintain the same order as in the Amazon documentation
*/
export const MARKETPLACES = {
  // NORTH AMERICA
  ca: {
    id: 'A2EUQ1WTGCTBG2',
    code: 'ca',
    name: t('generic:Countries.ca', 'Canada'),
    value: 'CA',
    languageCode: 'en-CA',
    isEnabled: true,
    topDomain: 'ca'
  },
  us: {
    id: 'ATVPDKIKX0DER',
    code: 'us',
    name: t('generic:Countries.us', 'United States'),
    value: 'US',
    languageCode: 'en-US',
    isEnabled: true,
    topDomain: 'com'
  },
  mx: {
    id: 'A1AM78C64UM0Y8',
    code: 'mx',
    name: t('generic:Countries.mx', 'Mexico'),
    value: 'MX',
    languageCode: 'es-MX',
    isEnabled: true,
    topDomain: 'com.mx'
  },
  br: {
    id: 'A2Q3Y263D00KWC',
    code: 'br',
    name: t('generic:Countries.br', 'Brazil'),
    value: 'BR',
    languageCode: 'br-PT',
    isEnabled: false,
    topDomain: 'com.br'
  },
  // EUROPE
  ie: {
    id: 'A28R8C7NBKEWEA',
    code: 'ie',
    name: t('generic:Countries.ie', 'Ireland'),
    value: 'IE',
    languageCode: 'en-US',
    isEnabled: false,
    topDomain: 'ie'
  },
  es: {
    id: 'A1RKKUPIHCS9HS',
    code: 'es',
    name: t('generic:Countries.es', 'Spain'),
    value: 'ES',
    languageCode: 'es-ES',
    isEnabled: true,
    topDomain: 'es'
  },
  uk: {
    id: 'A1F83G8C2ARO7P',
    code: 'uk',
    name: t('generic:Countries.uk', 'United Kingdom'),
    value: 'UK',
    languageCode: 'en-GB',
    isEnabled: true,
    topDomain: 'co.uk'
  },
  fr: {
    id: 'A13V1IB3VIYZZH',
    code: 'fr',
    name: t('generic:Countries.fr', 'France'),
    value: 'FR',
    languageCode: 'fr-FR',
    isEnabled: true,
    topDomain: 'fr'
  },
  be: {
    id: 'AMEN7PMS3EDWL',
    code: 'be',
    name: t('generic:Countries.be', 'Belgium'),
    value: 'BE',
    languageCode: 'fr-BE',
    isEnabled: false,
    topDomain: 'com.be'
  },
  nl: {
    id: 'A1805IZSGTT6HS',
    code: 'nl',
    name: t('generic:Countries.nl', 'Netherlands'),
    value: 'NL',
    languageCode: 'dut-NL',
    isEnabled: false,
    topDomain: 'nl'
  },
  de: {
    id: 'A1PA6795UKMFR9',
    code: 'de',
    name: t('generic:Countries.de', 'Germany'),
    value: 'DE',
    languageCode: 'de-DE',
    isEnabled: true,
    topDomain: 'de'
  },
  it: {
    id: 'APJ6JRA9NG5V4',
    code: 'it',
    name: t('generic:Countries.it', 'Italy'),
    value: 'IT',
    languageCode: 'it-IT',
    isEnabled: true,
    topDomain: 'it'
  },
  se: {
    id: 'A2NODRKZP88ZB9',
    code: 'se',
    name: t('generic:Countries.se', 'Sweden'),
    value: 'SE',
    languageCode: 'en-US',
    isEnabled: false,
    topDomain: 'se'
  },
  za: {
    id: 'AE08WJ6YKNBMC',
    code: 'za',
    name: t('generic:Countries.za', 'South Africa'),
    value: 'ZA',
    languageCode: 'en-US',
    isEnabled: false,
    topDomain: 'co.za'
  },
  pl: {
    id: 'A1C3SOZRARQ6R3',
    code: 'pl',
    name: t('generic:Countries.pl', 'Poland'),
    value: 'PL',
    languageCode: 'pl-PL',
    isEnabled: false,
    topDomain: 'pl'
  },
  eg: {
    id: 'ARBP9OOSHTCHU',
    code: 'eg',
    name: t('generic:Countries.eg', 'Egypt'),
    value: 'EG',
    languageCode: 'en-US',
    isEnabled: false,
    topDomain: 'eg'
  },
  tr: {
    id: 'A33AVAJ2PDY3EV',
    code: 'tr',
    name: t('generic:Countries.tr', 'Turkey'),
    value: 'TR',
    languageCode: 'tr-TR',
    isEnabled: false,
    topDomain: 'com.tr'
  },
  sa: {
    id: 'A17E79C6D8DWNP',
    code: 'sa',
    name: t('generic:Countries.sa', 'Saudi Arabia'),
    value: 'SA',
    languageCode: 'en-US',
    isEnabled: false,
    topDomain: 'sa'
  },
  ae: {
    id: 'A2VIGQ35RCS4UG',
    code: 'ae',
    name: t('generic:Countries.ae', 'United Arab Emirates'),
    value: 'AE',
    languageCode: 'en-US',
    isEnabled: false,
    topDomain: 'ae'
  },
  in: {
    id: 'A21TJRUUN4KGV',
    code: 'in',
    name: t('generic:Countries.in', 'India'),
    value: 'IN',
    languageCode: 'hi-IN',
    isEnabled: true,
    topDomain: 'in'
  },
  // FAR EAST
  sg: {
    id: 'A19VAU5U5O7RUS',
    code: 'sg',
    name: t('generic:Countries.sg', 'Singapore'),
    value: 'SG',
    languageCode: 'en-US',
    isEnabled: false,
    topDomain: 'sg'
  },
  au: {
    id: 'A39IBJ37TRP1C6',
    code: 'au',
    name: t('generic:Countries.au', 'Australia'),
    value: 'AU',
    languageCode: 'en-US',
    isEnabled: false,
    topDomain: 'com.au'
  },
  jp: {
    id: 'A1VC38T7YXB528',
    code: 'jp',
    name: t('generic:Countries.jp', 'Japan'),
    value: 'JP',
    languageCode: 'ja-JP',
    isEnabled: true,
    topDomain: 'co.jp'
  }
}

export const MARKETPLACES_REGIONS = {
  na: 'North America',
  eu: 'Europe',
  fe: 'Far East'
}

export const MARKETPLACES_IN_TRANSITION = {
  us: { code: 'us', name: 'United States', value: 'US', isEnabled: true },
  ca: { code: 'ca', name: 'Canada', value: 'CA', isEnabled: true },
  de: { code: 'de', name: 'Germany', value: 'DE', isEnabled: true },
  es: { code: 'es', name: 'Spain', value: 'ES', isEnabled: true },
  fr: { code: 'fr', name: 'France', value: 'FR', isEnabled: true },
  it: { code: 'it', name: 'Italy', value: 'IT', isEnabled: true },
  mx: { code: 'mx', name: 'Mexico', value: 'MX', isEnabled: true },
  uk: { code: 'uk', name: 'United Kingdom', value: 'UK', isEnabled: true },
  jp: { code: 'jp', name: 'Japan', value: 'JP', isEnabled: true },
  in: { code: 'in', name: 'India', value: 'IN', isEnabled: true }
}

export const REGIONS_WITH_COUNTRIES = {
  na: ['US', 'CA', 'BR', 'MX'],
  eu: [
    'FR',
    'DE',
    'UK',
    'ES',
    'NL',
    'IT',
    'SE',
    'PL',
    'AE',
    'TR',
    'SA',
    'EG',
    'IN'
  ],
  fe: ['AU', 'JP', 'SG']
}
